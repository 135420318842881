import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import Button from "../../components/Button";
import CustomizedInputs from "../../components/UIform";
import Loading from "../../components/Loading";
import { required } from "redux-form-validators";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const AlertForm = ({
	initialize,
	alert,
	handleSubmit,
	classes,
	t,
	loadStatus,
}) => {
	useEffect(() => {
		initialize({ text: alert });
	}, [initialize, alert]);

	return (
		<>
			<Typography variant="h6" className={classes.title}>
				{t("disableApp.messageText")}
			</Typography>
			<form
				onSubmit={handleSubmit}
				autoComplete="off"
				className={classes.container}
			>
				<div className={classes.row}>
					<Field
						name="text"
						component={CustomizedInputs}
						type="text"
						placeholder="Ваш текст"
						rows={"4"}
						multiline
						disabled={loadStatus}
						validate={required({ msg: t("modals.requiredField") })}
					/>
					<Button
						text={
							loadStatus ? (
								<Loading size={22} color={"#fff"} />
							) : (
								t("buttons.save")
							)
						}
						disabled={loadStatus}
						handleClick={handleSubmit}
					/>
				</div>
			</form>
		</>
	);
};

const styles = (theme) => ({
	title: {
		marginBottom: 10,
		marginTop: 20,
		fontSize: 18,
	},
	container: {
		backgroundColor: theme.palette.background.paper,
		display: "flex",
	},
	row: {
		display: "flex",
		margin: 15,
		flexWrap: "wrap",
		width: "100%",
	},
});

export default reduxForm({
	form: "AlertForm",
})(withTranslation()(withStyles(styles, { withTheme: true })(AlertForm)));
