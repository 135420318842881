const ADD_SHOPS = 'ADD-SHOPS';
const ADD_CATEGORY = 'ADD-CATEGORY';
const ADD_ADMIN_PERMISSION = 'ADD_ADMIN_PERMISSION';
const EDIT_CATEGORY = 'EDIT-CATEGORY';
const UPDATE_KEY_SHOP = 'UPDATE-KEY-SHOP';
const EDIT_SHOP = 'UPDATE-SHOP';
const UPDATE_SHOPS = 'UPDATE_SHOPS';
const UPDATE_LOAD_CONTROL = 'UPDATE_LOAD_CONTROL';
const UPDATE_CATEGORIES = 'UPDATE-CATEGORIES';
const UPDATE_CATEGORIES_ORDER = 'UPDATE-CATEGORIES-ORDER';
const EDIT_PRODUCT = 'EDIT-PRODUCT';
const UPDATE_PRODUCT = 'UPDATE-PRODUCT';
const UPDATE_PRODUCTS = 'UPDATE-PRODUCTS';
const DELETE_PRODUCT = 'DELETE-PRODUCT';
const UPDATE_LANGUAGES = 'UPDATE-LANGUAGES';
const UPDATE_DEFAULT_LANGUAGE = 'UPDATE-DEFAULT-LANGUAGE';
const UPDATE_ADMIN_SETTINGS = 'UPDATE-ADMIN-SETTINGS';
const UPDATE_SETTINGS = 'UPDATE-SETTINGS';
const CREATE_DELIVERY_DEFAULT = 'CREATE-DELIVERY-DEFAULT';
const CREATE_DELIVERY = 'CREATE-DELIVERY';
const UPDATE_DELIVERY = 'UPDATE-DELIVERY';
const DELETE_DELIVERY = 'DELETE-DELIVERY';
const UPDATE_WORKTIME = 'UPDATE-WORKTIME';
const UPDATE_WORKHOURS = 'UPDATE_WORKHOURS';
const UPDATE_DEFAULT_HOURS = 'UPDATE_DEFAULT_HOURS';
const UPDATE_VISIBILITY = 'UPDATE-VISIBILITY';
const UPDATE_PAYCARD = 'UPDATE-PAYCARD';
const UPDATE_ISDIRTY_WORKTIME = 'UPDATE_ISDIRTY_WORKTIME';
const UPDATE_DISCOUNT = 'UPDATE-DISCOUNT';
const UPDATE_PUSH_NOTIFICATIONS = 'UPDATE-PUSH-NOTIFICATIONS';

let initialState = {
   activeShop: null,
   defaultLanguages: '',
   shops: [],
   category: [],
   categories: [],
   languages: [],
   products: [],
   appSettings: {},
   keyShop: null,
   hiddenCategories: [],
   hiddenProducts: [],
   adminPermission: [],
   categoriesOrder: [],
   stopList: [],
}

const shopsReducer = (state = initialState, action) => {
    switch(action.type){
        case ADD_SHOPS: {
            return {
                ...state,
                shops: action.data.shops,
                appSettings: action.data.appSettings,
                category: action.data.category,
                hiddenCategories: action.data.category.hiddenCategories ? action.data.category.hiddenCategories : [],
                hiddenProducts: action.data.category.hiddenProducts ? action.data.category.hiddenProducts : [],
                stopList: action.data.category.stopList ? action.data.category.stopList : []
            }
        }
        case EDIT_SHOP: {
            let newStateShop = [...state.shops];
            newStateShop[action.data.key][action.data.params] = action.data.value;
            
            return {
                ...state,
                shops: newStateShop
            }
        }
        case UPDATE_SHOPS: {
            return {
                ...state,
                shops: action.shops
            }
        }
        case UPDATE_LOAD_CONTROL: {
            let appSettings = state.appSettings;
            const { orderTimeIntervalDelivery, orderTimeIntervalPickup} = action.loadControl;
            appSettings.orderTimeIntervalDelivery = orderTimeIntervalDelivery;
            appSettings.orderTimeIntervalPickup = orderTimeIntervalPickup;

            return {
                ...state,
                appSettings
            }
        }
        case UPDATE_VISIBILITY: {
            return {
                ...state,
                [action.data.params]: action.data.list
            }
        }
        case UPDATE_PAYCARD: {
            let {id, names, list} = action.data;

            for (let index in names) {
                state.shops[id].cardPaymentSystem[names[index]] = list[names[index]];
            }
            return state;
        }
        case UPDATE_KEY_SHOP: {
            return {
                ...state,
                keyShop: action.key
            }
        }
        case CREATE_DELIVERY_DEFAULT: {
            let { cityId, payload } = action.data;
            let newShops = state.shops;

            newShops[cityId] = { ...newShops[cityId], ...payload };

            return { ...state, shops: newShops };
        }
        case CREATE_DELIVERY: {
            let { cityId, deliveryZone } = action.data;
            let newShops = state.shops;
            
            newShops[cityId].deliveryZone = [ ...deliveryZone ];

            return { ...state, shops: newShops }
        }
        case UPDATE_DELIVERY: {
            let { keys: { cityId, zoneId }, deliveryZone } = action.data;
            let newShops = state.shops;
            
            newShops[cityId].deliveryZone[zoneId] = { ...deliveryZone };

            return { ...state, shops: newShops }
        }
        case DELETE_DELIVERY: {
            let { cityId, zoneId } = action.data;
            let newShops = state.shops;
            let filtredDeliveryZone = newShops[cityId].deliveryZone.filter((_, index) => index !== zoneId);

            newShops[cityId].deliveryZone[zoneId] = { ...filtredDeliveryZone };

            return { ...state, shops: newShops };
        }
        case UPDATE_WORKTIME: {
            return {
                ...state,
                shops: [
                   ...state.shops,
                   state.shops[action.data.key].workingHours = {
                       ...state.shops[action.data.key].workingHours,
                       begin: action.data.begin,
                       end: action.data.end
                   }
                ]
            }
        }
        case UPDATE_WORKHOURS: {
            let newStateShop = [...state.shops];
            newStateShop[action.data.key].workingHours = action.data.workingHours;
            
            return {
                ...state,
                shops: newStateShop
            }
        }
        case UPDATE_DEFAULT_HOURS: {
            return {
                ...state,
                shops: action.data
            }
        }
        case UPDATE_ADMIN_SETTINGS: {
            return {
                ...state,
                adminSettings: action.data
            }
        }
        case UPDATE_SETTINGS: {
            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    [action.data.params]: action.data.value
                }
            }
        }
        case UPDATE_ISDIRTY_WORKTIME: {
            return {
                ...state,
                isDirtyWorktime: action.data
            }
        }
        case ADD_CATEGORY: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    ...action.data,
                }
            }
        }
        case EDIT_CATEGORY: {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    [action.data.id]: action.data.category,
                }
            }
        }
        case UPDATE_CATEGORIES: {
            return {
                ...state,
                categories: action.data,
            }
        }
        case UPDATE_CATEGORIES_ORDER: {
            return {
                ...state,
                categoriesOrder: action.data
            }
        }
        case EDIT_PRODUCT: {
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.data.id]: {
                        ...state.products[action.data.id],
                        ...action.data.product
                    },
                },
            }
        }
        case UPDATE_PRODUCT: {
            return {
                ...state,
                products: {
                    ...state.products,
                    [action.data.id]: action.data.product
                },
            }
        }
        case UPDATE_PRODUCTS: {
            return {
                ...state,
                products: action.data,
            }
        }
        case DELETE_PRODUCT: {
            let filtredProducts = {};

            for (let key in state.products) {
                if (key !== action.id) {
                    filtredProducts[key] = state.products[key];
                }
            }

            return {
                ...state,
                products: filtredProducts,
            }
        }
        case UPDATE_LANGUAGES: {
            return {
                ...state,
                languages: action.data,
            }
        }
        case UPDATE_DEFAULT_LANGUAGE: {
            return {
                ...state,
                defaultLanguage: action.data,
            }
        }
        case UPDATE_DISCOUNT: {
            return {
                ...state,
                appSettings: {
                    ...state.appSettings,
                    [action.data.name]: action.data.value
                }
            }
        }
        case ADD_ADMIN_PERMISSION: {
            return {
                ...state,
                adminPermission: action.data
            }
        }
        case UPDATE_PUSH_NOTIFICATIONS: {
            const { cityId, pushNotifications } = action.data;
            let shops = [ ...state.shops ];
            shops[cityId].pushNotifications = pushNotifications

            return { ...state, shops };
        }
        default:
           return state;
    }
}

export const addShops = (dispatch, data) => dispatch({type: ADD_SHOPS, data});
export const addCategory = (dispatch, data) => dispatch({type: ADD_CATEGORY, data});
export const addAdminPermission = (dispatch, data) => dispatch({type: ADD_ADMIN_PERMISSION, data});
export const editCategory = (dispatch, data) => dispatch({type: EDIT_CATEGORY, data});
export const updateKeyShop = (key) => ({type: UPDATE_KEY_SHOP, key});
export const updateKeyShopDispatch = (dispatch, key) => dispatch({type: UPDATE_KEY_SHOP, key});
export const editShops = (data) => ({type: EDIT_SHOP, data});
export const updateShops = (shops) => ({type: UPDATE_SHOPS, shops});
export const updateLoadControl = (loadControl) => ({type: UPDATE_LOAD_CONTROL, loadControl});
export const updateAdminSettings = (dispatch, data) => dispatch({type: UPDATE_ADMIN_SETTINGS, data});
export const updateSettings = (data) => ({type: UPDATE_SETTINGS, data});
export const updateWorktime = (dispatch, data) => dispatch({type: UPDATE_WORKTIME, data});
export const updateWorkhours = (data) => ({type: UPDATE_WORKHOURS, data});
export const updateDefaultHours = (data) => ({type: UPDATE_DEFAULT_HOURS, data});
export const createDeliveryDefault = (payload, cityId) => ({type: CREATE_DELIVERY_DEFAULT, data: { payload, cityId }});
export const createDelivery = (deliveryZone, cityId) => ({type: CREATE_DELIVERY, data: { deliveryZone, cityId }});
export const updateDelivery = (deliveryZone, keys) => ({type: UPDATE_DELIVERY, data: { deliveryZone, keys }});
export const deleteDelivery = (cityId, zoneId) => ({type: DELETE_DELIVERY, data: { cityId, zoneId }});
export const updateVisibility = (data) => ({type: UPDATE_VISIBILITY, data});
export const updatePayCard = (data) => ({type: UPDATE_PAYCARD, data});
export const updateIsDirtyWorktime = (dispatch, data) => dispatch({type: UPDATE_ISDIRTY_WORKTIME, data});
export const updateCategories = (data) => ({type: UPDATE_CATEGORIES, data});
export const updateCategoriesDispatch = (dispatch, data) => dispatch({type: UPDATE_CATEGORIES, data});
export const updateProduct = (dispatch, data) => dispatch({type: UPDATE_PRODUCT, data});
export const updateProductsDispatch = (dispatch, data) => dispatch({type: UPDATE_PRODUCTS, data});
export const updateCategoriesOrder = (data) => ({type: UPDATE_CATEGORIES_ORDER, data});
export const updateCategoriesOrderDispatch = (dispatch, data) => dispatch({type: UPDATE_CATEGORIES_ORDER, data});
export const editProduct = (dispatch, data) => dispatch({type: EDIT_PRODUCT, data});
export const updateProducts = (data) => ({type: UPDATE_PRODUCTS, data});
export const deleteProduct = (id) => ({type: DELETE_PRODUCT, id});
export const updateLanguages = (dispatch, data) => dispatch({type: UPDATE_LANGUAGES, data});
export const updateDefaultLanguage = (dispatch, data) => dispatch({type: UPDATE_DEFAULT_LANGUAGE, data});
export const updateDiscount = (dispatch, data) => dispatch({type: UPDATE_DISCOUNT, data});
export const updatePushNotifications = (cityId,  pushNotifications) => ({type: UPDATE_PUSH_NOTIFICATIONS, data: { cityId, pushNotifications }});
// export const updateStopList = (stopList) => ({type: UPDATE_STOP_LIST, stopList});

export default shopsReducer;