import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateKeyShop, editShops, updateSettings } from "../../reducers/shops";
import { withFirebase } from "../../firebase";
import DisableApp from "./disableApp";

const DisableAppContainer = (props) => {
	const [alertShown, setAlertShown] = useState(false); // Контроль алерта
	const [switcherStatus, setSwitcherStatus] = useState();
	const [textAlert, setTextAlert] = useState("");
	const [isLoad, setIsLoad] = useState(false);

	const getTextAlert = () => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/settings/Alerts/openAppAlert`)
			.on("value", (snapshot) => {
				const data = snapshot.val();
				setTextAlert(data);
			});
	};

	// Обновление алерта
	const updateAlert = useCallback(
		async (data) => {
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));

			if (data.text !== textAlert) {
				// props.updateSettings({
				// 	params: "closedSpotAlertDescription",
				// 	value: data.text,
				// });

				try {
					await props.firebase.bd
						.ref(`${isDebug ? "debug" : ""}/settings/Alerts`)
						.update({
							openAppAlert: data.text,
						});

					alert("Готово");
				} catch (error) {
					console.error("Ошибка при обновлении данных:", error);
				}
			}
		},
		[props, alertShown]
	);

	const getSwitcherStatus = () => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/settings/appSettings/openAppAlert`)
			.on("value", (snapshot) => {
				const data = snapshot.val();
				setSwitcherStatus(data);
				setIsLoad(true);
			});
	};

	const updateSwitcher = async () => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		try {
			await props.firebase.bd
				.ref(`${isDebug ? "debug" : ""}/settings/appSettings`)
				.update({
					openAppAlert: !switcherStatus,
				});

			alert("Готово");
		} catch (error) {
			console.error("Ошибка при обновлении данных:", error);
		}
	};

	useEffect(() => {
		getTextAlert();
		getSwitcherStatus();
	}, []);

	return (
		<DisableApp
			isLoad={isLoad}
			switcherStatus={switcherStatus}
			updateSwitcher={updateSwitcher}
			closedAlert={props.closedAlert}
			alert={textAlert}
			editShops={props.editShops}
			updateAlert={updateAlert}
			isDebug={props.isDebug}
		/>
	);
};

export default withRouter(withFirebase(DisableAppContainer));
